.carousel-img:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.btn_color {
  background-color: #2E3094;
  color: white;
}